<template>
  <div>
    <div class="reservation-container">
      <div class="reservation-img">
        <img src="./image/pc.jpg" v-if="$store.state.pageset.pcshow" />
        <img src="./image/pc.jpg" v-else />
      </div>
      <div class="reservation-container-form">
        <p class="p1">欢迎关注七腾机器人</p>
        <p class="p2" v-animate="'queue-bottom'">请留下您的相关信息</p>
        <p class="p3" v-animate="'queue-bottom'">我们会及时与您联系</p>
        <el-form ref="form" :rules="formRules" :model="form">
          <el-form-item prop="name" v-animate="'queue-bottom'">
            <el-input v-model="form.name" placeholder="您的姓名"></el-input>
          </el-form-item>
          <el-form-item v-animate="'queue-bottom'"  prop="phone">
            <el-input v-model="form.phone" placeholder="手机号码"></el-input>
          </el-form-item>
          <el-form-item prop="corporateName" v-animate="'queue-bottom'">
            <el-input v-model="form.corporateName" placeholder="公司名称"></el-input>
          </el-form-item>
          <el-form-item prop="area" v-animate="'queue-bottom'">
            <el-input v-model="form.area" placeholder="省/市/区"></el-input>
            <!-- <el-cascader :props="props"></el-cascader> -->
          </el-form-item>
          <el-form-item v-animate="'queue-bottom'" prop="email" >
            <el-input v-model="form.email" placeholder="您的邮箱"></el-input>
          </el-form-item>
          <el-form-item prop="remark" v-animate="'queue-bottom'">
            <el-input
              type="textarea"
              v-model="form.remark"
              placeholder="信息说明"
              class="textarea-div"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          size="small"
          class="btn-div-experience black-btn"
          plain
          @click="orderNow"
          :loading="orderLoading"
          >提交</el-button
        >
        <div class="p4">
          <p>全国免费服务热线：400-8765-700</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import index from "./index.js";

export default index;
</script>
<style scoped lang="scss">
@import "index.scss";
@import "@/assets/style/common.scss";
</style>

// let cn = require('china-region');//引入中国地区接口
import { addChance } from '@/api/reservation/reservation.js';
import { validPhone, validEmail } from '@/utils/validate';
export default {
    components: {
    },
    data() {
        // 邮箱校验
        let validateEmail = (rule, value, callback) => {
            if (!validEmail(value)) {
                callback(new Error('请输入正确的邮箱'));
            } else {
                callback();
            }
        };
        // 手机号校验
        let validatePhone = (rule, value, callback) => {
            if (!validPhone(value)) {
                callback(new Error('请输入正确的手机号'));
            } else {
                callback();
            }
        };
        return {
            form: {},
            formRules: {
                name: [{
                    required: true,
                    message: '请输入姓名',
                    trigger: 'blur'
                }],
                corporateName: [{
                    required: true,
                    message: '请输入公司名称',
                    trigger: 'blur'
                }],
                email: [{
                    validator: validateEmail,
                    trigger: 'blur',
                    required: false,
                }],
                phone: [{
                    validator: validatePhone,
                    trigger: 'blur',
                    required: true,
                }],
            },
            orderLoading: false
        }
    },
    mounted() {
        let userInfo = this.$store.state.user.userInfo || null;
        if (userInfo) {
            this.form = {
                name: userInfo.userName,
                phone: userInfo.phonenumber,
                corporateName: userInfo.corporateName,
                area: userInfo.area,
                email: userInfo.email,
            };
        }
    },
    methods: {
        //打电话
        openDial() {
            window.location.href = 'tel:400-8765-700';
        },
        // 预约留资
        async orderNow() {
            // 后续考虑同步crm
            this.orderLoading = true;
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    let res = await addChance(this.form);
                    if (res.code === 200) {
                        this.$message.success('预约成功！');
                        this.orderLoading = false;
                    } else {
                        this.$message.error('预约失败，请联系电话预约');
                        this.orderLoading = false;
                    }
                } else {
                    this.$message.error('请填写必填项！');
                    this.orderLoading = false;
                }
            });
        }
    }
}